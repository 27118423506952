import React from "react";

import Layout from "../components/layout";
import styles from "./about.module.css";

export default function About() {
  return (
    <Layout
      seoTitle="About the firm"
      seoDescription="Know about our firm and the team. Additionally taking you through the journey we took in building this firm"
    >
      <div className={styles.container}>
        <h1 className={styles.aboutTitle}>About the firm</h1>
        <p className={styles.aboutPara}>
          <em>R L R BOSE & Co.</em> is a Chartered Accountants firm providing
          Assurance, Taxation , Advisory and other services. We laid the
          foundation stone of our firm on September 1, 2017 as a
          sole-proprietorship firm under the name & style of{" "}
          <em>R L R & Associates</em> with our office in Kolkata
          <br></br>
          Subsequently, on 1st April, 2019 with the admission of CA Priyanka
          Bose as Partner, the erstwhile sole-proprietorship firm was converted
          to a Partnership Firm with the name <em>R L R BOSE & Co</em>.
        </p>
        <p className={styles.aboutPara}>
          Revered for our professional ethos and technical expertise, we provide
          efficacious solutions to our client’s needs.
          <br></br>
          Our philosophy is of partnering with our clients and not being a
          distant service provider. Since each and every business is inherently
          different, we tailor our services to meet client’s specific needs.
          <br></br>
          We recruit, train, motivate and retain highly capable and sharpest
          talent, who bring quality in their work and deliver the best
          solutions.
        </p>
        <h2 className={styles.aboutTitle}>The Partners</h2>
        <div className={styles.partnerProfile}>
          <div className={styles.partnerAvatar}>
            <h3 className={styles.partnerName}>CA Raju Lal Rajak</h3>
            <img
              className={styles.partnerPicture}
              src="https://res.cloudinary.com/dd2lstwr8/image/upload/v1594539177/rlrbose.co.in/raju_lal_rajak.jpg"
              alt="CA Raju Lal Rajak"
            ></img>
          </div>
          <p className={styles.partnerDescription}>
            A commerce graduate from St. Xaviers' College, Kolkata, went on to
            complete his post-graduation from the University of Calcutta and has
            simultaneously completed his articleship from M/s Tirthankar &
            Associates, Chartered Accountants, 11/13, Selimpur Road,
            Kolkata-700031.
            <br></br>
            He was associated with Statutory and Internal Audits of various
            organisations including public and private companies, Cooperative
            Societies, partnership firms, proprietorship concerns and also
            Branch Statutory Inspection, Revenue Audit, Stock Audit, Audit of
            Receivables & Concurrent Audits of various Nationalised Banks. He
            has also done Filing of Charges and Searching of Charges with the
            Registrar of Companies, West Bengal on behalf of branches of
            Nationalised and other Banks. He has also handled the work of an
            Official Liquidator.
          </p>
        </div>
        <div className={styles.partnerProfile}>
          <div className={styles.partnerAvatar}>
            <h3 className={styles.partnerName}>CA Priyanka Bose</h3>
            <img
              className={styles.partnerPicture}
              src="https://res.cloudinary.com/dd2lstwr8/image/upload/v1594538862/rlrbose.co.in/priyanka_bose.jpg"
              alt="CA Priyanka Bose"
            ></img>
          </div>
          <p className={styles.partnerDescription}>
            A commerce graduate from Sivanath Sastri College, Kolkata, has
            completed her articleship from M/s Tirthankar & Associates,
            Chartered Accountants, 11/13, Selimpur Road, Kolkata-700031. She has
            completed the Post Qualification Course on Forensic Audit & Fraud
            Detection (FAFD) from The Institute of Chartered Accountants of
            India (ICAI).
            <br></br>
            She played an immensely vital role behind the establishment of R L R
            & Associates in 2017 (now known as R L R BOSE & Co.)
            <br></br>
            She was associated with Statutory and Internal Audits of various
            organisations including public and private companies, Cooperative
            Societies, Trusts , partnership firms, proprietorship concerns and
            also Branch Statutory Inspection, Concurrent Audits of various
            Nationalised Banks. She has also done Filing of Charges and
            Searching of Charges with the Registrar of Companies, West Bengal on
            behalf of branches of nationalized and other Banks. She has also
            handled the work of an Official Liquidator.
          </p>
        </div>
      </div>
    </Layout>
  );
}
